import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, {
  Mapped,
  Optional,
  Tuple,
} from "@/__main__/data-model.mjs";

const PlayerDetails = {
  avatar: Optional(String),
  id: Optional(String),
  name: String,
  platform: Optional(String),
  steam_id: Optional(String),
};

const PlayerData = Tuple([String, Tuple([Number, [Number]])]);

const SearchItem = Tuple([
  PlayerData,
  Mapped({
    key: String,
    value: PlayerDetails,
  }),
]) satisfies ModelDefinition;

const SearchItems = [SearchItem] satisfies ModelDefinition;

export const SearchResults = {
  data: SearchItems,
} satisfies ModelDefinition;

export const SearchItemsModel = createModel(SearchItems).transform(
  SearchResults,
  (data) => {
    return { data };
  },
);

export type SearchItemType = FromModel<typeof SearchItem>;

export type SearchItemsType = FromModel<typeof SearchItemsModel>;
