import getData from "@/__main__/get-data.mjs";
import { appURLs, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { GameProfileSearchResults } from "@/data-models/search-players.mjs";
import type { SearchItemType } from "@/data-models/search-players-v2.mjs";
import { SearchItemsModel } from "@/data-models/search-players-v2.mjs";
import { GAME_SYMBOL_DEADLOCK } from "@/game-deadlock/definition-symbol.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import StaticFortnite from "@/game-fortnite/utils/static.mjs";

export default async function fetchSearchPlayersV2(
  query: string,
  game: symbol,
): Promise<GameProfileSearchResults> {
  const { data } = await getData(
    `${appURLs.SEARCH_V2}/search/index/${query}`,
    SearchItemsModel,
    undefined,
    {
      skipSafetyCheck: true,
    },
  );
  if (game === GAME_SYMBOL_DEADLOCK) {
    const reduced: Array<SearchItemType["1"]> = Object.values(
      data.reduce((acc, cur) => {
        for (const k in cur[1]) {
          const parsed = cur[1][k];
          if (!parsed.steam_id) continue;
          if (acc[parsed.steam_id]) continue;
          acc[parsed.steam_id] = parsed;
        }
        return acc;
      }, Object.create(null)),
    );
    // @ts-ignore
    const hits: GameProfileSearchResults["hits"] = reduced.map((i) => ({
      game: GAME_SHORT_NAMES[GAME_SYMBOL_DEADLOCK],
      id: i.steam_id,
      metadata: { avatar: i.avatar },
      name: i.name,
      searchScore: 0,
      shard: "",
      textMatchScore: 0,
    }));
    return {
      found: hits.length,
      hits,
      timer: {
        endTimestamp: new Date(),
        postProcessing: 0,
        preProcessing: 0,
        searching: 0,
        startTimestamp: new Date(),
        total: 0,
      },
    };
  }

  if (game === GAME_SYMBOL_FORTNITE) {
    const reduced: Array<SearchItemType["1"]> = Object.values(
      data.reduce((acc, cur) => {
        for (const k in cur[1]) {
          const parsed = cur[1][k];
          if (parsed?.platform !== "epic") continue;
          if (acc[parsed.id]) continue;
          acc[parsed.id] = parsed;
        }
        return acc;
      }, Object.create(null)),
    );
    // @ts-ignore
    const hits: GameProfileSearchResults["hits"] = reduced.map((i) => ({
      game: GAME_SHORT_NAMES[GAME_SYMBOL_FORTNITE],
      id: i.id,
      metadata: { avatar: StaticFortnite.getProfileImage(i.id) },
      name: i.name,
      searchScore: 0,
      shard: "",
      textMatchScore: 0,
    }));
    return {
      found: hits.length,
      hits,
      timer: {
        endTimestamp: new Date(),
        postProcessing: 0,
        preProcessing: 0,
        searching: 0,
        startTimestamp: new Date(),
        total: 0,
      },
    };
  }
}
