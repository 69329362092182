import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Any, Optional, Silent } from "@/__main__/data-model.mjs";

export const gameProfile = {
  game: String,
  id: String,
  // TODO lol, mapping value still gives a warning, also wrapping value with
  // Silent here causes a hard crash on validation
  metadata: Silent(Any),
  name: String,
  searchScore: Number,
  shard: Silent(String),
  snippet: Optional(String), // this may be null when searching with external API
  textMatchScore: Number,
} satisfies ModelDefinition;

export type GameProfileSearchResult = FromModel<typeof gameProfile>;

export const GameProfilesTimer = {
  endTimestamp: Date,
  postProcessing: Number,
  preProcessing: Number,
  searching: Number,
  startTimestamp: Date,
  total: Number,
} satisfies ModelDefinition;

export const GameProfilesResponse = {
  found: Number,
  hits: [gameProfile],
  timer: GameProfilesTimer,
} satisfies ModelDefinition;

const GameProfileSearchModel = createModel({
  data: {
    gameProfiles2: GameProfilesResponse,
  },
}).transform(GameProfilesResponse, (resp) => resp.data.gameProfiles2);

export type GameProfileSearchResults = FromModel<typeof GameProfileSearchModel>;

export default GameProfileSearchModel;
