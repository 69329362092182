import { appURLs, GAME_BACKEND_NAMES } from "@/app/constants.mjs";
import { GameProfilesResponse } from "@/data-models/search-players.mjs";
import { GAME_SEARCH } from "@/search/fetch-search-data.mjs";
import { queryGQL } from "@/util/gql-formatters.mjs";
import gql from "@/util/graphql-query.mjs";

const gameProfilesTemplate = gql`
  query (
    $boostGameScore: Boolean
    $boostShardScore: [String!]
    $game: SupportedGame!
    $name: String!
    $page: Int
    $perPage: Int
    $prefetchPages: Int
    $shards: [String!]
  ) {
    gameProfiles2(
      boostGameScore: $boostGameScore
      boostShardScore: $boostShardScore
      game: $game
      name: $name
      page: $page
      perPage: $perPage
      prefetchPages: $prefetchPages
      shards: $shards
    ) { ${GameProfilesResponse} }
  }
`;

// transform for caching purposes
function transformPlayerQuery(query: string): string {
  return query.toLowerCase().replace(" #", "#");
}
export function gameProfiles(name: string, game: symbol, count = 20, shards?) {
  const boostShardScore = GAME_SEARCH[game].players.boostShards?.() || [];
  const boostGameScore = false;

  return queryGQL(appURLs.SEARCH, gameProfilesTemplate, {
    boostGameScore,
    boostShardScore,
    game: GAME_BACKEND_NAMES[game],
    name: transformPlayerQuery(name),
    page: 1,
    perPage: count,
    prefetchPages: 1,
    shards,
  });
}
